import { Injectable } from '@angular/core';
import { BaseGroup } from '../../../shared/models/base-group.model';
import { FilingTypeKey } from '../../../shared/models/filing-type-key.enum';
import { GroupType } from '../../../shared/models/group-type.enum';
import {
  AgencyType,
  Role,
  RoleKey,
  RoleType,
} from '../../../shared/models/role.model';
import { SessionService } from '../../../shared/services/session.service';
import { Group } from '../../../shared/models/group.model';
import { Agency } from '../../../shared/models/agency.model';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor(
    private sessionService: SessionService,
  ) {}

  public isSystemAdministrator(): boolean {
    const roles = this.sessionService.getRolesData();

    return roles.reduce((admin, role) => {
      return admin || role.type == RoleType.SystemAdministrator;
    }, false);
  }

  public isAdmin(): boolean {
    const roles = this.sessionService.getRolesData();

    return roles.reduce((admin, role) => {
      return (
        admin ||
        role.surrogateKey == RoleKey.SystemAdministrator ||
        role.surrogateKey == RoleKey.SystemSuperuser
      );
    }, false);
  }

  public hasRole(roleToFind: RoleKey): boolean {
    const roles = this.sessionService.getRolesData();

    return roles.reduce((hasRole, role) => {
      return hasRole || role.surrogateKey == roleToFind;
    }, false);
  }

  public canUserManageAgencies(): boolean {
    const roles = this.sessionService.getRolesData();

    return roles.reduce((can, role) => {
      return can || this.canRoleManageAgencies(role);
    }, false);
  }

  private canRoleManageAgencies(role: Role): boolean {
    if (role.agencyType == AgencyType.OGEOversight) {
      return true;
    }

    switch (role.type) {
      case RoleType.AgencyAdministrator:
      case RoleType.SystemAdministrator:
        return true;
    }

    switch (role.surrogateKey) {
      case RoleKey.AgencyDAEO:
      case RoleKey.AgencyRecordsManager:
      case RoleKey.PPOLead:
      case RoleKey.WHCOLead:
        return true;
      default:
        return false;
    }
  }

  public canUserAddAgencies(): boolean {
    return this.isAdmin();
  }

  public canUserManageAssignedReports(
    groupList: any,
    groupType: GroupType
  ): boolean {
    let retVal = false;

    if (groupType === GroupType.Regular) {
      retVal = this.canUserViewReportAssignments(groupList);
    } else if (groupType === GroupType.InboundNominee) {
      retVal = this.canUserViewNomineeReportAssignments(groupList);
    }

    return retVal;
  }

  public canUserViewGroup(groupIds: string[]): boolean {
    const roles = this.sessionService.getRolesData();

    return (
      roles.reduce((show, role) => {
        return show || this.canRoleOutsideGroupViewGroup(role);
      }, false) ||
      groupIds.reduce((show, id) => {
        return (
          show ||
          roles
            // Limit to roles for this group
            .filter((role) => role.group.surrogateKey == id)
            .reduce((show, role) => {
              return show || this.canRoleInGroupViewGroup(role);
            }, false)
        );
      }, false)
    );
  }

  private canRoleOutsideGroupViewGroup(role: Role): boolean {
    switch (role.surrogateKey) {
      case RoleKey.OGEAdministrator:
      case RoleKey.OGECertifyingOfficial:
      case RoleKey.OGEDirector:
      case RoleKey.OGENomineeCertifyingOfficial:
      case RoleKey.OGENomineeProgramManager:
      case RoleKey.OGENomineeRecordsManager:
      case RoleKey.OGENomineeReviewer:
      case RoleKey.OGERecordsManager:
      case RoleKey.OGEReviewer:
      case RoleKey.SystemAdministrator:
      case RoleKey.SystemAssistant:
      case RoleKey.SystemSuperuser:
        return true;
      case RoleKey.Router:
        return role.agencyType == AgencyType.OGEOversight;
      default:
        return false;
    }
  }

  private canRoleInGroupViewGroup(role: Role): boolean {
    switch (role.surrogateKey) {
      case RoleKey.AgencyAdministrator:
      case RoleKey.AgencyDAEO:
      case RoleKey.AgencyRecordsManager:
      case RoleKey.CertifyingOfficial:
      case RoleKey.EthicsOfficial:
      case RoleKey.Screener:
      case RoleKey.NomineeReviewer:
      case RoleKey.PointOfContact:
      case RoleKey.PPOLead:
      case RoleKey.PPOReviewer:
      case RoleKey.PTTLead:
      case RoleKey.PTTReviewer:
      case RoleKey.RecordsManager:
      case RoleKey.Router:
      case RoleKey.Screener:
      case RoleKey.Supervisor:
      case RoleKey.WHCOLead:
      case RoleKey.WHCOReviewer:
        return true;
      default:
        return false;
    }
  }

  // This covers both agencies and groups
  public canUserManageGroup(groupIds: string[]): boolean {
    const roles = this.sessionService.getRolesData();

    return (
      this.isAdmin() ||
      groupIds.reduce((show, id) => {
        return (
          show ||
          roles
            // Limit to roles for this group
            .filter((role) => role.group.surrogateKey == id)
            .reduce((show, role) => {
              return show || this.canRoleManageGroup(role);
            }, false)
        );
      }, false)
    );
  }

  private canRoleManageGroup(role: Role): boolean {
    switch (role.surrogateKey) {
      case RoleKey.AgencyAdministrator:
      case RoleKey.AgencyDAEO:
      case RoleKey.CertifyingOfficial:
      case RoleKey.EthicsOfficial:
      case RoleKey.Screener:
      case RoleKey.OGEAdministrator:
      case RoleKey.OGEDirector:
      case RoleKey.OGENomineeCertifyingOfficial:
      case RoleKey.PointOfContact:
      case RoleKey.PPOLead:
      case RoleKey.PPOReviewer:
      case RoleKey.PTTLead:
      case RoleKey.PTTReviewer:
      case RoleKey.Router:
      case RoleKey.Screener:
      case RoleKey.Supervisor:
      case RoleKey.SystemAdministrator:
      case RoleKey.SystemAssistant:
      case RoleKey.WHCOLead:
      case RoleKey.WHCOReviewer:
        return true;
      default:
        return false;
    }
  }

  public canUserManageFilers(groupIds: string[]): boolean {
    const roles = this.sessionService.getRolesData();

    return (
      this.isAdmin() ||
      groupIds.reduce((show, id) => {
        return (
          show ||
          roles
            // Limit to roles for this group
            .filter((role) => role.group.surrogateKey == id)
            .reduce((show, role) => {
              return show || this.canRoleManageFilers(role);
            }, false)
        );
      }, false)
    );
  }

  private canRoleManageFilers(role: Role): boolean {
    switch (role.surrogateKey) {
      case RoleKey.AgencyAdministrator:
      case RoleKey.AgencyDAEO:
      case RoleKey.CertifyingOfficial:
      case RoleKey.EthicsOfficial:
      case RoleKey.Screener:
      case RoleKey.PointOfContact:
      case RoleKey.PPOLead:
      case RoleKey.PPOReviewer:
      case RoleKey.PTTLead:
      case RoleKey.PTTReviewer:
      case RoleKey.Router:
      case RoleKey.Screener:
      case RoleKey.Supervisor:
      case RoleKey.WHCOLead:
      case RoleKey.WHCOReviewer:
        return true;
      default:
        return false;
    }
  }
  
  public canUserBulkAssignReports(groupIds: string[]): boolean {
    const roles = this.sessionService.getRolesData();

    return (
      this.isAdmin() ||
      groupIds.reduce((show, id) => {
        return (
          show ||
          roles
            // Limit to roles for this group
            .filter((role) => role.group.surrogateKey == id)
            .reduce((show, role) => {
              return show || this.canRoleBulkAssignReports(role);
            }, false)
        );
      }, false)
    );
  }

  private canRoleBulkAssignReports(role: Role): boolean {
    switch (role.surrogateKey) {
      case RoleKey.AgencyAdministrator:
      case RoleKey.AgencyDAEO:
        return true;
      default:
        return false;
    }
  }

  public canUserBulkExportReports(
    groupIds: string[],
    agencyId: string
  ): boolean {
    const roles = this.sessionService.getRolesData();

    return groupIds.reduce((show, id) => {
      return (
        show ||
        roles
          // Limit to roles for this group
          .reduce((show, role) => {
            return show || this.canRoleBulkExportReports(role, id, agencyId);
          }, false)
      );
    }, false);
  }

  private canRoleBulkExportReports(
    role: Role,
    groupId: string,
    agencyId: string
  ): boolean {
    switch (role.surrogateKey) {
      case RoleKey.AgencyAdministrator:
        if (
          role.group.surrogateKey === agencyId &&
          role.isSpecialAgencyType()
        ) {
          return true;
        }
        return false;
      case RoleKey.AgencyDAEO:
      case RoleKey.AgencyRecordsManager:  
        if (role.group.surrogateKey == agencyId) {
          return true;
        }
        return false;
      case RoleKey.OGECertifyingOfficial:
      case RoleKey.OGENomineeCertifyingOfficial:
      case RoleKey.OGENomineeRecordsManager:
      case RoleKey.OGERecordsManager:
      case RoleKey.OGEDirector:
        if (role.agencySurrogateKey == agencyId) {
          return true;
        }
        return false;
      case RoleKey.PPOLead:
      case RoleKey.WHCOLead:
        if (role.group.surrogateKey == groupId) {
          return true;
        } else {
          return false;
        }
      default:
        return false;
    }
  }

  public canUserBulkExportReportsForGroup(
    groupIds: string[],
    agencyId: string,
    agencyType: AgencyType
  ): boolean {
    if (agencyType != AgencyType.Regular) {
      return false;
    }

    return this.canUserBulkExportReports(groupIds, agencyId);
  }

  public canUserBulkExportNonNomineeReports(
    groupIds: string[],
    agencyId: string
  ): boolean {
    const roles = this.sessionService.getRolesData();

    return (
      this.canUserBulkExportReports(groupIds, agencyId) &&
      groupIds.reduce((show, id) => {
        return (
          show ||
          roles
            // Limit to roles for this group
            .reduce((show, role) => {
              return (
                show ||
                this.canRoleBulkExportNonNomineeReports(role, id, agencyId)
              );
            }, false)
        );
      }, false)
    );
  }

  private canRoleBulkExportNonNomineeReports(
    role: Role,
    groupId: string,
    agencyId: string
  ): boolean {
    switch (role.surrogateKey) {
      case RoleKey.AgencyDAEO:
      case RoleKey.AgencyRecordsManager:  
        if (role.group.surrogateKey == agencyId) {
          return true;
        }
        return false;
      case RoleKey.OGECertifyingOfficial:
      case RoleKey.OGERecordsManager:
      case RoleKey.OGEDirector:
        if (role.agencySurrogateKey == agencyId) {
          return true;
        }
        return false;

      default:
        return false;
    }
  }

  public canUserBulkExportNomineeReports(
    groupIds: string[],
    agencyId: string
  ): boolean {
    const roles = this.sessionService.getRolesData();
    return (
      this.canUserBulkExportReports(groupIds, agencyId) &&
      groupIds.reduce((show, id) => {
        return (
          show ||
          roles
            // Limit to roles for this group
            .reduce((show, role) => {
              return (
                show || this.canRoleBulkExportNomineeReports(role, id, agencyId)
              );
            }, false)
        );
      }, false)
    );
  }

  private canRoleBulkExportNomineeReports(
    role: Role,
    groupId: string,
    agencyId: string
  ): boolean {
    switch (role.surrogateKey) {
      case RoleKey.AgencyAdministrator:
        if (
          role.group.surrogateKey === agencyId &&
          role.isSpecialAgencyType()
        ) {
          return true;
        }
        return false;
      case RoleKey.AgencyDAEO:
      case RoleKey.AgencyRecordsManager:  
        if (role.group.surrogateKey == agencyId) {
          return true;
        }
        return false;
      case RoleKey.OGEDirector:
      case RoleKey.OGENomineeCertifyingOfficial:
      case RoleKey.OGENomineeRecordsManager:
        if (role.agencySurrogateKey == agencyId) {
          return true;
        }
        return false;
      case RoleKey.PPOLead:
      case RoleKey.WHCOLead:
        if (role.group.surrogateKey == groupId) {
          return true;
        } else {
          return false;
        }

      default:
        return false;
    }
  }

  public canUserBulkExportDraftNomineeReports(
    groupIds: string[],
    agencyId: string
  ): boolean {
    const roles = this.sessionService.getRolesData();
    return (
      this.canUserBulkExportNomineeReports(groupIds, agencyId) &&
      groupIds.reduce((show, id) => {
        return (
          show ||
          roles
            // Limit to roles for this group
            .reduce((show, role) => {
              return (
                show || this.canRoleBulkExportDraftNomineeReports(role, id, agencyId)
              );
            }, false)
        );
      }, false)
    );
  }

  private canRoleBulkExportDraftNomineeReports(
    role: Role,
    groupId: string,
    agencyId: string
  ): boolean {
    switch (role.surrogateKey) {
      case RoleKey.AgencyAdministrator:
        if (
          role.group.surrogateKey === agencyId &&
          role.isSpecialAgencyType()
        ) {
          return true;
        }
        return false;
      case RoleKey.AgencyDAEO:
        if (role.group.surrogateKey == agencyId) {
          return true;
        }
        return false;
      case RoleKey.OGEDirector:
      case RoleKey.OGENomineeCertifyingOfficial:
      case RoleKey.OGENomineeRecordsManager:
        if (role.agencySurrogateKey == agencyId) {
          return true;
        }
        return false;
      case RoleKey.PPOLead:
      case RoleKey.WHCOLead:
        if (role.group.surrogateKey == groupId) {
          return true;
        } else {
          return false;
        }

      default:
        return false;
    }
  }

  public canUserViewReportAssignments(groupIds: string[]): boolean {
    const roles = this.sessionService.getRolesData();

    return groupIds.reduce((show, id) => {
      return (
        show ||
        roles
          // Limit to roles for this group
          .filter((role) => role.group.surrogateKey == id)
          .reduce((show, role) => {
            return show || this.canRoleViewReportAssignments(role);
          }, false)
      );
    }, false);
  }

  private canRoleViewReportAssignments(role: Role): boolean {
    switch (role.surrogateKey) {
      case RoleKey.AgencyAdministrator:
      case RoleKey.AgencyDAEO:
      case RoleKey.CertifyingOfficial:
      case RoleKey.EthicsOfficial:
      case RoleKey.Screener:
      case RoleKey.PPOLead:
      case RoleKey.PPOReviewer:
      case RoleKey.PTTLead:
      case RoleKey.PTTReviewer:
      case RoleKey.Router:
      case RoleKey.Screener:
      case RoleKey.Supervisor:
      case RoleKey.WHCOLead:
      case RoleKey.WHCOReviewer:
        return true;
      default:
        return false;
    }
  }

  public canUserViewNomineeReportAssignments(groupIds: string[]): boolean {
    const roles = this.sessionService.getRolesData();

    return groupIds.reduce((show, id) => {
      return (
        show ||
        roles
          // Limit to roles for this group
          .filter((role) => role.group.surrogateKey == id)
          .reduce((show, role) => {
            return show || this.canRoleViewNomineeReportAssignments(role);
          }, false)
      );
    }, false);
  }

  private canRoleViewNomineeReportAssignments(role: Role): boolean {
    switch (role.surrogateKey) {
      case RoleKey.PPOLead:
      case RoleKey.PPOReviewer:
      case RoleKey.PTTLead:
      case RoleKey.PTTReviewer:
      case RoleKey.WHCOLead:
      case RoleKey.WHCOReviewer:
        return true;
      default:
        return false;
    }
  }

  public canUserManageRole(
    role: RoleKey,
    group: BaseGroup,
    filingType: FilingTypeKey | undefined = undefined
  ): boolean {
    /* WARNING: ALWAYS BE CERTAIN THIS STAYS IN SYNC WITH THE CODE 
    IN THE user_can_manage_group_role() FUNCTION IN POSTGRES! */

    const roles = this.sessionService.getRolesData();

    const isSuperUser = this.hasRole(RoleKey.SystemSuperuser);
    const isHelpDesk = this.hasRole(RoleKey.SystemAdministrator);
    const isOgeDirector = this.hasRole(RoleKey.OGEDirector);
    const isOgeAdministrator = this.hasRole(RoleKey.OGEAdministrator);
    const agency = group.getAgency();

    switch (role) {
      case RoleKey.SystemSuperuser:
        return isSuperUser;
      case RoleKey.AgencyAdministrator:
        if (isHelpDesk || isSuperUser) return true;

        switch (agency?.agencyType) {
          case AgencyType.Regular:
            return this.hasRolesInGroup(group, [
              RoleKey.AgencyDAEO,
              RoleKey.AgencyAdministrator,
            ]);
          case AgencyType.PTT:
            return this.hasRolesInGroup(group, [
              RoleKey.PTTLead,
              RoleKey.AgencyAdministrator,
            ]);
          case AgencyType.PPO:
            return this.hasRolesInGroup(group, [
              RoleKey.PPOLead,
              RoleKey.AgencyAdministrator,
            ]);
          case AgencyType.WHCO:
            return this.hasRolesInGroup(group, [
              RoleKey.WHCOLead,
              RoleKey.AgencyAdministrator,
            ]);
          default:
            return false;
        }
      case RoleKey.AgencyDAEO:
        return isHelpDesk || isSuperUser;
      case RoleKey.AgencyRecordsManager:
        return (
          isHelpDesk || isSuperUser ||
          (agency?.agencyType === AgencyType.Regular &&
            this.hasRolesInGroup(group, [
              RoleKey.AgencyAdministrator,
            ])
            )
          ) ? true : false;
          case RoleKey.CertifyingOfficial:
      case RoleKey.EthicsOfficial:
        return (
          isHelpDesk ||
          isSuperUser ||
          this.hasRolesInGroup(
            group,
            [
              RoleKey.AgencyDAEO,
              RoleKey.AgencyAdministrator,
              RoleKey.CertifyingOfficial,
            ],
            filingType
          )
        );
      case RoleKey.PPOLead: // Except that Help Desk users currently can't even see special agencies or their groups
      case RoleKey.PTTLead: // Except that Help Desk users currently can't even see special agencies or their groups
      case RoleKey.OGEDirector:
      case RoleKey.WHCOLead: // Except that Help Desk users currently can't even see special agencies or their groups
      case RoleKey.SystemAdministrator:
      case RoleKey.SystemAssistant:
        return isHelpDesk || isSuperUser;
      case RoleKey.OGEAdministrator:
        return isOgeDirector || isOgeAdministrator || isHelpDesk || isSuperUser;
      case RoleKey.NomineeReviewer:
      case RoleKey.PointOfContact:
      case RoleKey.RecordsManager:
        return (
          isHelpDesk ||
          isSuperUser ||
          this.hasRolesInGroup(group, [
            RoleKey.AgencyAdministrator,
            RoleKey.AgencyDAEO,
            RoleKey.CertifyingOfficial,
          ])
        );
      case RoleKey.Supervisor:
        return (
          isHelpDesk ||
          isSuperUser ||
          this.hasRolesInGroup(group, [
            RoleKey.AgencyAdministrator,
            RoleKey.AgencyDAEO,
            RoleKey.CertifyingOfficial,
            RoleKey.PointOfContact,
          ])
        );
      case RoleKey.Screener:
        return (
          isHelpDesk ||
          isSuperUser ||
          this.hasRolesInGroup(
            group,
            [
              RoleKey.AgencyAdministrator,
              RoleKey.AgencyDAEO,
              RoleKey.CertifyingOfficial,
            ],
            filingType
          )
        );
      case RoleKey.PTTReviewer:
        return (
          isHelpDesk ||
          isSuperUser ||
          this.hasRolesInGroup(group, [
            RoleKey.AgencyAdministrator,
            RoleKey.PTTLead,
          ])
        );
      case RoleKey.PPOReviewer:
        return (
          isHelpDesk ||
          isSuperUser ||
          this.hasRolesInGroup(group, [
            RoleKey.AgencyAdministrator,
            RoleKey.PPOLead,
          ])
        );
      case RoleKey.WHCOReviewer:
        return (
          isHelpDesk ||
          isSuperUser ||
          this.hasRolesInGroup(group, [
            RoleKey.AgencyAdministrator,
            RoleKey.WHCOLead,
          ])
        );
      case RoleKey.Router:
      case RoleKey.Router:
        if (agency && agency.agencyType == AgencyType.OGEOversight) {
          return (
            isOgeDirector ||
            isHelpDesk ||
            isSuperUser ||
            this.hasRolesInGroup(group, [
              RoleKey.OGEAdministrator,
              RoleKey.OGENomineeCertifyingOfficial,
            ])
          );
        } else {
          return (
            isHelpDesk ||
            isSuperUser ||
            this.hasRolesInGroup(group, [
              RoleKey.AgencyAdministrator,
              RoleKey.AgencyDAEO,
              RoleKey.CertifyingOfficial,
            ])
          );
        }
      case RoleKey.OGECertifyingOfficial:
      case RoleKey.OGENomineeCertifyingOfficial:
      case RoleKey.OGENomineeProgramManager:
      case RoleKey.OGENomineeRecordsManager:
      case RoleKey.OGENomineeReviewer:
      case RoleKey.OGERecordsManager:
      case RoleKey.OGEReviewer:
        return (
          isOgeDirector ||
          isOgeAdministrator ||
          isHelpDesk ||
          isSuperUser ||
          this.hasRolesInGroup(group, [RoleKey.OGENomineeCertifyingOfficial])
        );
    }

    return false;
  }

  public hasRolesInGroup(
    group: BaseGroup,
    rolesToFind: RoleKey[],
    filingType: FilingTypeKey | undefined = undefined
  ) {
    const roles = this.sessionService.getRolesData();
    const groupIds = group.getHierarchyGroupIds();

    return groupIds.reduce((isRole, id) => {
      return (
        isRole ||
        roles
          // Limit to roles for this group and, if applicable, filing type
          .filter(
            (role) =>
              role.group.surrogateKey == id &&
              (!filingType ||
                !role.filingType ||
                (filingType && role.filingType == filingType))
          )
          .reduce((isRole, role) => {
            return isRole || rolesToFind.includes(role.surrogateKey);
          }, false)
      );
    }, false);
  }
  
  public canEditSpecialNotices(): boolean {
    return this.hasOgeAccess();
  }

  public hasOgeAccess(): boolean {
    return [
      RoleKey.OGEAdministrator,
      RoleKey.SystemAdministrator,
      RoleKey.SystemSuperuser,
    ].reduce((has, role) => {
      return has || this.hasRole(role);
    }, false);
  }

  public canEditBulkRecordActionNotificationMessage(): boolean {
    return [RoleKey.SystemAdministrator, RoleKey.SystemSuperuser].reduce(
      (has, role) => {
        return has || this.hasRole(role);
      },
      false
    );
  }

  public canUserSeeGroupId(group: BaseGroup): boolean {
    return (
      [
        RoleKey.SystemAdministrator,
        RoleKey.SystemAssistant,
        RoleKey.SystemSuperuser,
      ].reduce((has, role) => {
        return has || this.hasRole(role);
      }, false) ||
      this.hasRolesInGroup(group, [
        RoleKey.AgencyAdministrator,
        RoleKey.AgencyDAEO,
      ])
    );
  }

  public canUserViewFilers(agency: Agency, group: Group): boolean {
    if (!group.isNomineeGroup() || agency.agencyType === AgencyType.Regular) {
      return true;
    }
    const roles = this.sessionService.getRolesData();
    return (
      roles
        .filter(
          (role) =>
            // Limit to roles for this group
            role.group.surrogateKey === group.surrogateKey ||
            // But also include SU, HD
            [
              RoleKey.SystemSuperuser, 
              RoleKey.SystemAdministrator,
            ].includes(
              role.surrogateKey
            )
        )
        .reduce((show, role) => {
          return show || this.canRoleViewNomineeFilers(role, agency.agencyType);
        }, false)
    );
  }

  /**
   * WHCO Admin, Reviewer and Lead should not see Nominees tab
   * OGE-7305
   */
  private canRoleViewNomineeFilers(role: Role, agencyType: AgencyType): boolean {
    if (role.surrogateKey === RoleKey.AgencyAdministrator && agencyType === AgencyType.WHCO) {
      return false;
    }
    switch (role.surrogateKey) {
      case RoleKey.SystemSuperuser:
      case RoleKey.SystemAdministrator:
      case RoleKey.WHCOLead:  
      case RoleKey.WHCOReviewer:
        return false;
      default:
        return true;
    }
  }

}
